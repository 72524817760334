import React from 'react';
import BrickLogoSrc from '@/assets/brickLogo.png';
import WhiteLogoSrc from '@/assets/logos/whiteLogo.svg';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material';

const MainLogo: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <img
        style={{
          height: 28,
          marginTop: 6,
        }}
        src={theme.palette.mode === 'light' ? BrickLogoSrc : WhiteLogoSrc}
        alt="Ark"
        onClick={() => navigate('/')}
      />
    </Box>
  );
};

export default MainLogo;
