import {Box, Button, Collapse} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  ConversationListItemFragment,
  useListConversationsQuery,
} from '../../generated/graphql';
import {useSelectedCommunities} from '../CommunityFilter';
import {SearchBar} from '../SearchBar';
import {ConversationListItemProps} from './ConversationListItem';
import {
  ConversationsFilters,
  FilterName,
  FilterValues,
  openFilter,
  tenantOpenFilter,
} from './ConversationsFilters';
import {ConversationsList} from './ConversationsList';
import {FilterAlt, FilterAltOff} from '@mui/icons-material';
import {edgesToNodes} from '../../utils/edgesToNodes';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      marginBottom: '12px',
      gap: '12px',
    },
  },
  search: {
    width: 300,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export type ConversationListItemData = ConversationListItemFragment;

export type EnterpriseNewConversationProps = {
  communityId?: string;
  homeId?: string;
  residencyId?: string;
  residentId?: string;
  onCreate?: (id: string) => void;
  contactId?: string;
};

export type NewConversationProps = {
  open: boolean;
  onClose: () => void;
} & EnterpriseNewConversationProps;

export type Tabs = 'thread' | 'details';

export type Sender = 'Resident' | 'Manager';

export type ViewConversationProps = {
  open: boolean;
  conversationId: string;
  onClose: () => void;
  returnPath?: string;
  conversationListSlot?: React.ReactNode;
};

type Props = {
  enabledFilters: FilterName[];
  ConversationListItemComponent: React.FC<ConversationListItemProps>;
  NewConversationComponent: React.FC<NewConversationProps>;
  ViewConversationComponent: React.FC<ViewConversationProps>;
  contactId?: string;
  clientId?: string;
  residencyId?: string;
};

export const Conversations = ({
  ConversationListItemComponent,
  NewConversationComponent,
  ViewConversationComponent,
  enabledFilters,
  contactId,
  clientId,
  residencyId,
}: Props) => {
  const styles = useStyles();
  const communities = useSelectedCommunities();
  const [viewConversationOpen, setViewConversationOpen] = useState(false);
  const [newConversation, setNewConversation] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const {conversationId} = useParams<{conversationId: string | undefined}>();
  const [search, setSearch] = useState('');
  const [filterValues, setFilterValues] = useState<FilterValues>({
    //@ts-ignore
    communities: [],
    date: [],
    home: [],
    status: [openFilter],
    orderBy: [{label: 'Newest', value: '-last_event_time'}],
    tenantStatus: [tenantOpenFilter],
    assignedTo: [{label: 'All', value: 'All'}],
  });

  useEffect(() => {
    setViewConversationOpen(Boolean(conversationId));
  }, [conversationId]);

  const statusFilterValues = (
    enabledFilters.includes('tenantStatus')
      ? filterValues.tenantStatus
      : filterValues.status
  )
    .map(s => s.value.split(','))
    .flat();

  const {data, loading, fetchMore} = useListConversationsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      search,
      orderBy: filterValues.orderBy[0]?.value,
      communities,
      status: statusFilterValues,
      date: filterValues.date[0]?.value,
      home: filterValues.home[0]?.value,
      contactId,
      clientId,
      residencyId,
      assignedTo:
        filterValues.assignedTo[0]?.value === 'All'
          ? undefined
          : filterValues.assignedTo[0]?.value,
    },
  });

  const hasMore = data?.conversations?.pageInfo?.hasNextPage;
  const onBottom = () => {
    if (hasMore) {
      fetchMore({
        variables: {
          after: data.conversations.pageInfo.endCursor,
        },
      });
    }
  };

  const isFiltered = ![
    filterValues.date.length === 0,
    filterValues.status.length === 0,
    communities.length === 0,
    search === '',
  ].every(Boolean);

  return (
    <>
      <Box className={styles.header}>
        <Box display="flex" gap={1} flexWrap="wrap" height={40}>
          <SearchBar
            onChange={setSearch}
            label="Search for a conversation..."
          />
          <Button
            onClick={() => setShowFilters(!showFilters)}
            sx={{
              minWidth: {xs: '100%', sm: 100},
              height: 40,
            }}
            variant="faded"
            startIcon={showFilters ? <FilterAltOff /> : <FilterAlt />}
          >
            {showFilters ? 'Hide' : 'Show'} filters
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              setNewConversation(true);
            }}
            color="secondary"
            className={styles.button}
            variant="contained"
          >
            New conversation
          </Button>
        </Box>
      </Box>
      <Collapse in={showFilters}>
        <ConversationsFilters
          enabledFilters={enabledFilters}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      </Collapse>
      <ConversationsList
        isFiltered={isFiltered}
        data={edgesToNodes(data?.conversations?.edges)}
        ListItemComponent={ConversationListItemComponent}
        onScrolledToBottom={onBottom}
        loading={loading}
        showStatusBorder={filterValues.status.length !== 1}
      />
      <NewConversationComponent
        communityId={undefined}
        open={newConversation}
        onClose={() => setNewConversation(false)}
      />
      {conversationId && (
        <ViewConversationComponent
          open={viewConversationOpen}
          conversationId={conversationId}
          onClose={() => setViewConversationOpen(false)}
          conversationListSlot={
            <ConversationsList
              isFiltered={isFiltered}
              data={edgesToNodes(data?.conversations?.edges)}
              ListItemComponent={ConversationListItemComponent}
              onScrolledToBottom={onBottom}
              loading={loading}
              showStatusBorder={filterValues.status.length !== 1}
              loadMoreButton={
                hasMore ? (
                  <Button fullWidth onClick={onBottom} variant="faded">
                    Load more
                  </Button>
                ) : null
              }
            />
          }
        />
      )}
    </>
  );
};
